import { Injectable } from '@angular/core';
import { Globals } from '../shared/globals';
import { Router } from '@angular/router';
import { DataUser } from '../models/user_model';
@Injectable({
    providedIn: 'root'
})
export class SessionService {
    constructor(
        public router: Router,
        private globals: Globals
    ) { }
    public logout() {
        this.globals.showMenu = 0;
        if (this.globals.dataUser) {
            this.globals.subject.next({
                type: 0,
                body: 'logout',
                id: this.globals.tokenCompleted,
                id_sender: this.globals.dataUser._id,
                type_user: 2,
                username: this.globals.dataUser.username
            });
        }
        sessionStorage.removeItem('tokenTime');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('dataUser');
        this.globals.dataUser = {} as DataUser;
        this.router.navigate(['/']);
    }
}
