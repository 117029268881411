<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="row page-titles">
    <div class="col-md-6 col-8 align-self-center">
        <h3 class="text-themecolor m-b-0 m-t-0">{{pageInfo?.title}}</h3>
        <!-- <ol class="breadcrumb">
            <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
                <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                    <a href='javascript:void(0)'>{{url.title}}</a>
                </li>
                <li class="breadcrumb-item active" *ngIf="last">{{url.title}}</li>
            </ng-template>
        </ol> -->
    </div>
    <div class="col-md-6 col-4 align-self-center">
        <!-- <button class="right-side-toggle waves-effect waves-light btn-info btn-circle btn-sm pull-right m-l-10">
            <i class="ti-settings text-white"></i>
        </button>
        <button class="btn pull-right hidden-sm-down btn-success">
            <i class="mdi mdi-plus-circle"></i> Create</button> -->
    </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
