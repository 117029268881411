import { Injectable, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class EventEmitterService {
    successConnectSocket = new EventEmitter();
    reloadCaptcha = new EventEmitter();
    updateChatSupport = new EventEmitter();
    sendMessageTransaction = new EventEmitter();
    isLoading = new EventEmitter();
}
