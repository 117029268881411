import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
    Router,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError
} from '@angular/router';
import { EventEmitterService } from '../../services/event-emitter.service';
import { Globals } from '../globals';
@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
    viewLoading = true;
    loadFirst = false;
    constructor(
        private eventEmitterService: EventEmitterService,
        private cd: ChangeDetectorRef,
        public globals: Globals,
        private router: Router,
    ) {
        // this.cd.detach();
    }
    ngOnInit() {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                this.cd.detectChanges();
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.cd.detectChanges();
            }
        }, () => {
            this.cd.detectChanges();
        });
        // esperar el inicio del webSocket
        this.eventEmitterService.successConnectSocket.subscribe(() => {
            if (!this.loadFirst) {
                this.viewLoading = false;
                this.loadFirst = true;
                this.cd.detectChanges();
            }
        });
        this.eventEmitterService.isLoading.subscribe((data: any) => {
            if (this.viewLoading !== data) {
                this.viewLoading = data;
                this.cd.detectChanges();
            }
        });
    }
}
