import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EventEmitterService } from '../services/event-emitter.service';
import { environment } from '../../environments/environment';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(
        private eventEmitterService: EventEmitterService,
        ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url !== `${environment.apiUrl}/user/refreshdata` && req.url !== `${environment.apiUrl}/equation`) {
            this.eventEmitterService.isLoading.emit(true);
        }
        return next.handle(req).pipe(
            finalize(() => {
                this.eventEmitterService.isLoading.emit(false);
            })
        );
    }
}
