<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header" style="border-bottom: 1px solid gray;">
            <a class="navbar-brand" href="/dashboard">
                <!-- Logo icon -->
                <b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <!-- <img src="assets/Grupo_1648.svg" alt="homepage" class="dark-logo" width="40" />

                    <img src="assets/Grupo_1648.svg" alt="homepage" class="light-logo" width="40" /> -->
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                &nbsp;
                <span>
                    <!-- dark Logo text -->
                    <img src="assets/Grupo_1648.svg" alt="homepage" class="dark-logo" width="150" />
                    <!-- Light Logo text -->
                    <img src="assets/Grupo_1648.svg" class="light-logo" alt="homepage" width="150" />
                </span>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto mt-md-0 ">
                <!-- This is  -->
                <li class="nav-item">
                    <!-- <a class="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark" href="javascript:void(0)" (click)="changeShowStatus()">
                        <i class="ti-menu"></i>
                    </a> -->
                </li>
                <li class="nav-item">
                    <!-- <a class="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark" href="javascript:void(0)" (click)="changeShowStatus()">
                        <i class="icon-arrow-left-circle"></i>
                    </a> -->
                </li>
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <li class="nav-item">
                    <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                        <a href="javascript:void(0);" class="nav-link dropdown-toggle text-muted waves-effect waves-dark" id="dropdownBasic1" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-user text-white"></i>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu dropdown-menu-right">
                            <div class="container text-center text-white">
                                <p>{{globals.dataUser.username}}</p>
                                <p>{{globals.dataUser.email.address}}</p>
                            </div>
                            <a href="javascript:void(0);" ngbDropdownItem (click)="sessionLog.logout()">
                                <i class="fa fa-power-off"></i> Logout
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>