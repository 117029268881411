import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { SessionService } from '../../services/session.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit, AfterViewInit {
    name: string;
    showHide: boolean;
    constructor(
        public sessionLog: SessionService,
        public router: Router,
        public globals: Globals,
    ) {
        this.showHide = true;
    }

    ngOnInit() {
    }

    changeShowStatus() {
        this.showHide = !this.showHide;
    }

    ngAfterViewInit() {
    }
}
