import { Injectable } from '@angular/core';
import { DataUser, DataChat, DataChatSupport } from '../models/user_model';
import { webSocket } from 'rxjs/webSocket';
import { environment } from '../../environments/environment';

@Injectable()
export class Globals {
    dataUser: DataUser = JSON.parse(sessionStorage.getItem('dataUser'));
    chatSupport: Array<DataChatSupport> = [];
    dataChat: DataChat = { _id: '', participants: [], messages: [] };
    showMenu = 0;
    chatTransaction: any = [];
    notificationSupport = false;
    receiver = '';
    idChatTransaction = '';
    priceBTC: Float64Array;
    percentageBTC: Float64Array;
    tokenCompleted = '';
    subject = webSocket(environment.apiWebsocket);
    itemChildren = '';
    statusNavigation = 1;

    pageOpen = 1;
    sizeOpen = 10;
    longOpen = 0;
    pageAdvertisements = 1;
    sizeAdvertisements = 10;
    longAdvertisements = 0;
    pageCompleted = 1;
    sizeCompleted = 10;
    longCompleted = 0;
    pageCancelled = 1;
    sizeCancelled = 10;
    longCancelled = 0;
    pageDispute = 1;
    sizeDispute = 10;
    longDispute = 0;
    pageExpires = 1;
    sizeExpires = 10;
    longExpires = 0;
    searchOpen = '';
    searchAdvertisements = '';
    searchCompleted = '';
    searchCancelled = '';
    searchDispute = '';
    searchExpires = '';
    /**
     * Reemplaza una cadena con la ruta de la imagen
     * por otra donde se obtiene el thumbnail de la misma.
     * @param img string
     */
    public Thumbnail(img: string): string {
        if (img.substr(-3).toLowerCase() !== 'svg') {
            let result = '';
            const imgA = img.split('/');
            const file = imgA.pop();
            imgA.forEach((item, index) => {
                if (item !== '') {
                    result += '/' + item;
                }
            });
            img = environment.imgServer + result + '/Thumbnail-' + file;

        }
        return img;
    }
}
