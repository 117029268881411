import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ConnectApiService } from '../../services/connect-api.service';
import { Globals } from '../globals';
import { EventEmitterService } from '../../services/event-emitter.service';
import { SessionService } from '../../services/session.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, AfterViewInit {
    // this is for the open close
    isActive = true;
    showMenu = '';
    showSubMenu = '';
    menu = [];
    year = (new Date()).getFullYear();
    constructor(
        public sessionLog: SessionService,
        private connectApi: ConnectApiService,
        public globals: Globals,
        private eventEmitterService: EventEmitterService,
        private cd: ChangeDetectorRef,
    ) { }
    ngOnInit() {
        this.showMenu = 'Dashboard';
        if (this.globals.tokenCompleted !== '') {
            this.getMenu();
        } else {
            // esperar el inicio del webSocket
            this.eventEmitterService.successConnectSocket.subscribe(() => {
                this.getMenu();
            });
        }
    }
    getMenu() {
        this.connectApi.getMenu().subscribe((res: any) => {
            this.menu = res.content.menu;
        });
    }
    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }
    activeChildren(chil: string) {
        this.globals.itemChildren = chil;
        this.cd.detectChanges();
    }
    addActiveClass(element: any) {
        if (element === this.showSubMenu) {
            this.showSubMenu = '0';
        } else {
            this.showSubMenu = element;
        }
    }
    eventCalled() {
        this.isActive = !this.isActive;
    }
    // End open close
    ngAfterViewInit() {
    }
}
