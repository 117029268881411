<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- End User profile text-->
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav" id="style-11">
            <ul id="sidebarnav">
                <li class="nav-small-cap">MENU</li>
                <li *ngFor="let item of menu" [class.active]="showMenu === item.title" (click)="addExpandClass(item.title)">
                    <a [ngClass]="item.children ? 'has-arrow': ''" [routerLink]="item.url">
                        <i [ngClass]="item.icon"></i>
                        <span class="hide-menu">{{item.title}}</span>
                    </a>
                    <ul *ngFor="let itemChildren of item.children" aria-expanded="false" class="collapse" [class.in]="showMenu === item.title">
                        <li *ngIf="itemChildren.title!='Member create'">
                            <a [style.color]="globals.itemChildren == itemChildren.title ? '#1f8ad8' : ''" (click)="addExpandClass(item.title); activeChildren(itemChildren.title)" [routerLink]="itemChildren.url">{{itemChildren.title}}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
    <!-- Bottom points-->
    <div class="sidebar-footer">
        <a href="javascript:void(0);" class="link" title="Mylocalcoin"><small>© {{year}}</small></a>
        <a href="javascript:void(0);" (click)="sessionLog.logout()" class="link" data-toggle="tooltip" title="Logout">
            <i class="mdi mdi-power"></i>
        </a>
    </div>
    <!-- End Bottom points-->
</aside>