import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConnectApiService } from './services/connect-api.service';
import { SessionService } from './services/session.service';
import { delay, tap, retryWhen } from 'rxjs/operators';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import * as momentZone from 'moment-timezone';
import { Globals } from './shared/globals';
import { EventEmitterService } from './services/event-emitter.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    alive = true;
    constructor(
        private connectApi: ConnectApiService,
        public sessionLog: SessionService,
        private globals: Globals,
        private eventEmitterService: EventEmitterService,
        private cookieService: CookieService
    ) {
        setInterval(() => {
            if (sessionStorage.getItem('token') && this.globals.dataUser) {
                // todays date
                const now = momentZone(new Date()).tz('America/New_York');
                // another date
                const end = moment(moment.unix(parseInt(sessionStorage.getItem('tokenTime'), 10)).format('YYYY-MM-DD HH:mm:ss'));
                const duration = moment.duration(now.diff(end));
                const minutes = duration.asMinutes() * -1;
                if (minutes < 0) {
                    Swal.close();
                    this.sessionLog.logout();
                } else if (minutes <= 2) {
                    /*  Swal.fire({
                         title: 'Your session is about to expire',
                         text: 'do you want to renew it?',
                         icon: 'warning',
                         showCancelButton: true,
                         confirmButtonColor: '#3085d6',
                         confirmButtonText: 'Yes',
                         allowOutsideClick: false
                     }).then((result) => {
                         if (result.value) { */
                    this.connectApi.refreshToken().subscribe(() => { });
                    /* } else {
                        this.sessionLog.logout();
                    }
                }) */
                }
            }
        }, 25000);
    }
    ngOnInit() {
        if (!this.cookieService.get('AutoritationBrowser')) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789' + new Date().getTime().toString();
            const charactersLength = characters.length;
            for (let i = 0; i < 31; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            this.cookieService.set('AutoritationBrowser', result);
        }
        this.globals.subject.pipe(
            retryWhen(errors =>
                errors.pipe(
                    tap(err => {
                        console.error('Got error', err);
                    }),
                    delay(1000)
                )
            )
        ).subscribe((msg: any) => {
            if (msg.type === 3) {
                this.globals.tokenCompleted = msg.body;
                if (sessionStorage.getItem('dataUser')) {
                    this.globals.subject.next({
                        type: 2,
                        body: 'Asign to id user',
                        id: this.globals.tokenCompleted,
                        id_sender: this.globals.dataUser._id,
                        type_user: 1,
                        username: this.globals.dataUser.username
                    });
                }
                this.eventEmitterService.successConnectSocket.emit();
            } else if (msg.type === 5) {
                this.eventEmitterService.updateChatSupport.emit(msg);
            } else if (msg.type === 10) {
                this.eventEmitterService.sendMessageTransaction.emit(msg);
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                text: 'There is a problem with the connection ' + error
            });
        });
    }
    ngOnDestroy() {
        this.alive = false; // switches your IntervalObservable off
    }
}
