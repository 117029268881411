import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserLogin } from '../models/user_model';
import { environment } from '../../environments/environment';
import * as moment from 'moment-timezone';
@Injectable({
    providedIn: 'root'
})
export class ConnectApiService {
    constructor(
        private httpClient: HttpClient
    ) { }
    loginUser(data: UserLogin) {
        return this.httpClient.post(`${environment.apiUrl}/admin/signin`, data);
    }
    getListMembers(page: number, size: number, key: string, roles: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/userslists/${page}/${size}/${key}/${roles}`);
    }
    getMenu() {
        return this.httpClient.get(`${environment.apiUrl}/menu`);
    }
    getCountMembers() {
        return this.httpClient.get(`${environment.apiUrl}/userscount`);
    }
    getSearchKey(page: number, size: number, key: any) {
        return this.httpClient.get(`${environment.apiUrl}/userssearchkey/${page}/${size}/${key}`);
    }
    updateStatusMembers(data: object) {
        return this.httpClient.post(`${environment.apiUrl}/changeuserstatus`, data);
    }
    allSettings() {
        return this.httpClient.get(`${environment.apiUrl}/settings/all`);
    }
    updateMailgun(apikey: string, apiurl: string) {
        return this.httpClient.put(`${environment.apiUrl}/settings/mailgun`, {
            apikey,
            apiurl
        });
    }
    updateRecaptcha(siteKey: string, secretKey: string) {
        return this.httpClient.put(`${environment.apiUrl}/settings/grecaptcha`, {
            site_key: siteKey,
            secret_key: secretKey
        });
    }
    updateTwilio(sid: string, token: string, phone: string) {
        return this.httpClient.put(`${environment.apiUrl}/settings/twilio`, {
            sid,
            token,
            number: phone
        });
    }
    updateMaintenanceWhiteList(list) {
        return this.httpClient.put(`${environment.apiUrl}/settings/maintenance`, list);
    }
    updateFees(trade_buyer: number, trade_seller: number, external_deposit: number, internal_deposit: number, external_withdrawal: number, internal_withdrawal: number, mining: number, deductible_percentage: number) {
        return this.httpClient.put(`${environment.apiUrl}/settings/fees`, {
            trade_buyer,
            trade_seller,
            external_deposit,
            internal_deposit,
            external_withdrawal,
            internal_withdrawal,
            mining,
            deductible_percentage,
        });
    }
    updatePublicacion(minAmount: number) {
        return this.httpClient.put(`${environment.apiUrl}/settings/publication`, { min_amount: minAmount });
    }
    getListTemplate(lang: string) {
        return this.httpClient.get(`${environment.apiUrl}/email/templatelang/${lang}`);
    }
    createTemplate(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/email/templatelang`, data);
    }
    updateTemplate(id: string, data: object) {
        return this.httpClient.put(`${environment.apiUrl}/email/templatelang/${id}`, data);
    }
    SignupMember(data) {
        return this.httpClient.post(`${environment.apiUrl}/admin/signup`, data);
    }
    updateDataMembers(id: string, data: any) {
        return this.httpClient.put(`${environment.apiUrl}/updatedatamember/${id}`, data);
    }
    getListEvents(page: number, size: number, key: string, type: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/event/list/${page}/${size}/${key}/${type}`);
    }
    getAbout(name: string) {
        return this.httpClient.get(`${environment.apiUrl}/settings/about/${name}`);
    }
    updateAbout(data: object) {
        return this.httpClient.put(`${environment.apiUrl}/settings/updateabout`, data);
    }
    refreshToken() {
        return this.httpClient.get(`${environment.apiUrl}/user/refreshdata`);
    }
    /* Countries */
    getListCountries(page: number, size: number, key: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/countries/${page}/${size}/${key}`);
    }
    deleteCountrie(id: string) {
        return this.httpClient.delete(`${environment.apiUrl}/countries/${id}`);
    }
    updateCountrie(id: string, data: object) {
        return this.httpClient.put(`${environment.apiUrl}/countries/${id}`, data);
    }
    addCountrie(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/countries`, data);
    }

    /* Currencies */
    getListCurrencies(page: number, size: number, key: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/currencies/${page}/${size}/${key}`);
    }
    deleteCurrencie(id: string) {
        return this.httpClient.delete(`${environment.apiUrl}/currencies/${id}`);
    }
    updateCurrencie(id: string, data: object) {
        return this.httpClient.put(`${environment.apiUrl}/currencies/${id}`, data);
    }
    addCurrencie(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/currencies`, data);
    }

    /* Payment */
    getListPayment(page: number, size: number, key: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/paymentmethods/${page}/${size}/${key}`);
    }
    deletePayment(id: string) {
        return this.httpClient.delete(`${environment.apiUrl}/paymentmethods/${id}`);
    }
    updatePayment(id: string, data: object) {
        return this.httpClient.put(`${environment.apiUrl}/paymentmethods/${id}`, data);
    }
    addPayment(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/paymentmethods`, data);
    }

    /* support */
    getChat(page: number, size: number, key: string, online: string, attended: string, country: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/chat/chatsupport/${page}/${size}/${key}/${online}/${attended}/${country}`);
    }
    getChatCompleted(id) {
        return this.httpClient.get(`${environment.apiUrl}/chat/contentchat/${id}/0`);
    }
    getListDisputes(page: number, size: number, key: string, online: string, attended: string, country: string) {
        return this.httpClient.get(`${environment.apiUrl}/support/disputes/${page}/${size}/${key}/${online}/${attended}/${country}`);
    }
    getContentDisputes(id, action) {
        return this.httpClient.get(`${environment.apiUrl}/chat/contentchat/${id}/${action}`);
    }
    getSummaryTransaction(id) {
        return this.httpClient.get(`${environment.apiUrl}/support/summarytransaction/${id}`);
    }
    finishDisputes(id: any, data: any) {
        return this.httpClient.put(`${environment.apiUrl}/support/finishdispute/${id}`, data);
    }
    allChat(typ: number, page: number, size: number, key: string, online: string, attended: string, country: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/support/allchat/${typ}/${page}/${size}/${key}/${online}/${attended}/${country}`);
    }
    finishChat(id: string, data: object) {
        return this.httpClient.put(`${environment.apiUrl}/chat/finishchat/${id}`, data);
    }

    getListMembersToValidate(page: number, size: number, key: string) {
        if (key === '') {
            key = '0';
        }
        // return this.httpClient.get(`${environment.apiUrl}/userslists/${page}/${size}/${key}`);
        return this.httpClient.get(`${environment.apiUrl}/users/validate/identity/${page}/${size}/${key}`);
    }
    memberValidate(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/users/validate/identity/update`, data);
    }
    uploadFileChat(data: any, id: string) {
        const input = new FormData();
        input.append('file', data);
        input.append('id', id);
        return this.httpClient.post(`${environment.apiUrl}/chat/uploadfile`, input);
    }
    /* Roles */
    getListRoles(key: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/role/${key}`);
    }
    getListAccess() {
        return this.httpClient.get(`${environment.apiUrl}/role/access`);
    }
    getListAccessForRole(id) {
        return this.httpClient.get(`${environment.apiUrl}/role/accessrole/${id}`);
    }
    addRole(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/role`, data);
    }
    updateRole(id: string, data: any) {
        return this.httpClient.put(`${environment.apiUrl}/role/${id}`, data);
    }
    deleteRole(id: string) {
        return this.httpClient.delete(`${environment.apiUrl}/role/${id}`);
    }
    listAllCountries() {
        return this.httpClient.get(`${environment.apiUrl}/listallcountries`);
    }
    listAllCurrencies() {
        return this.httpClient.get(`${environment.apiUrl}/listallcurrencies`);
    }
    // Reports
    printReportTransactions(location: string, business: string, status: number, start: string, end: string) {
        return this.httpClient.get(`${environment.apiUrl}/reports/transactions/${location}/${business}/${status}/${start}/${end}`);
    }
    printReportUsers(location: string, status: number, start: string, end: string) {
        return this.httpClient.get(`${environment.apiUrl}/reports/users/${location}/${status}/${start}/${end}`);
    }
    getLisReports(page: number, size: number, key: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/reports/members/${page}/${size}/${key}`);
    }
    getPageAnalyzer(yearAnalyzer: string) {
        return this.httpClient.get(`${environment.apiUrl}/dashboard/pageanalyzer/${yearAnalyzer}`);
    }
    getPageAnalyzerTransactiondirect(yearAnalyzer: string) {
        return this.httpClient.get(`${environment.apiUrl}/dashboard/pageanalyzertransactiondirect/${yearAnalyzer}`);
    }
    viewReport(id: string) {
        return this.httpClient.get(`${environment.apiUrl}/reports/membersview/${id}`);
    }
    getListPublications(page: number, size: number, user: string, key: string, location: string, typePublications: string, typeBusiness: string, status: number, start: string, end: string) {
        if (key === '') {
            key = '0';
        }
        const timeZone = moment.tz.guess(true);
        const data: any = {
            page,
            size,
            user,
            key,
            location,
            time_zone: timeZone,
            type_publication: typePublications,
            type_business: typeBusiness,
            status,
            start,
            end
        };
        return this.httpClient.post(`${environment.apiUrl}/publications/`, data);
    }
    getListTradings(page: number, size: number, key: string, location: string, business: string, status: number, start: string, end: string) {
        if (key === '') {
            key = '0';
        }
        const data: any = {
            page,
            size,
            key,
            location,
            type_business: business,
            status,
            start,
            end
        };
        return this.httpClient.post(`${environment.apiUrl}/tradings/`, data);
    }
    getListTransactions(page: number, size: number, user: string, search: string, type_transaction: number, start: string, end: string) {
        const data: any = {
            page,
            size,
            user,
            search,
            type_transaction,
            start,
            end
        };
        return this.httpClient.post(`${environment.apiUrl}/transactions/`, data);
    }
    getUserId(id: string) {
        return this.httpClient.get(`${environment.apiUrl}/usersdetails/geid/${id}`);
    }
    getByUsername(username: any) {
        return this.httpClient.get(`${environment.apiUrl}/usersdetails/username/${username}`);
    }
    getDataImportant(userName: string) {
        return this.httpClient.get(`${environment.apiUrl}/usersdetails/summary/${userName}`);
    }
    getAdvertisementsPost(page: number, size: number, key: string, id: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/usersdetails/advertisements/${page}/${size}/${key}/${id}`);
    }
    getPostTransactionUser(page: number, size: number, key: string, status: any, id: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/usersdetails/transactions/${page}/${size}/${key}/${status}/${id}`);
    }
    getTransactionsWallet(page: number, size: number, datem: string, datey: string, id: string) {
        return this.httpClient.get(`${environment.apiUrl}/usersdetails/gettransaction/${page}/${size}/${datem}/${datey}/${id}`);
    }
    calculateFee(id: string) {
        return this.httpClient.get(`${environment.apiUrl}/calculatefee/${id}`);
    }
    getListFeedbacks(page: number, size: number, id: string) {
        return this.httpClient.get(`${environment.apiUrl}/usersdetails/feedbacks/${page}/${size}/${id}`);
    }
    checkTwoFActorQr(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/twofactor/check`, data);
    }
}
