import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { Globals } from './globals';
import { SessionService } from '../services/session.service';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { EventEmitterService } from '../services/event-emitter.service';
import { environment } from '../../environments/environment';

@Injectable()
export class InterceptorErrosClass implements HttpInterceptor {
    constructor(
        public globals: Globals,
        public sessionLog: SessionService,
        private eventEmitterService: EventEmitterService,
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && event.body.access.Token !== '') {
                    sessionStorage.setItem('tokenTime', event.body.access.Expire);
                    sessionStorage.setItem('token', event.body.access.Token);
                }
            }),
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    try {
                        if (req.url === `${environment.apiUrl}/admin/signin`) {
                            this.eventEmitterService.reloadCaptcha.emit();
                        }
                        if (err.error.status === 403) {
                            Swal.fire({
                                title: 'Token error',
                                text: err.error.message,
                                icon: 'warning',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK',
                                allowOutsideClick: false
                            }).then(() => {
                                this.sessionLog.logout();
                            });
                        } else {
                            Swal.fire('', err.error.message, 'error');
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
                return of(err);
            })
        );
    }
}
