import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SIDEBAR_TOGGLE_DIRECTIVES } from './shared/sidebar.directive';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { RightSidebarComponent } from './shared/right-sidebar/rightsidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Globals } from './shared/globals';
import { JwtModule } from '@auth0/angular-jwt';
export function jwtTokenGetter() {
    return sessionStorage.getItem('token');
}
import { InterceptorDeviceIDClass } from './shared/deviceid-interceptor-class';
import { InterceptorErrosClass } from './shared/erros-interceptor-class';
import { LoaderInterceptor } from './shared/loader-interceptor-class';
import { EventEmitterService } from './services/event-emitter.service';
import { CookieService } from 'ngx-cookie-service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingComponent } from './shared/loading/loading.component';
@NgModule({
    declarations: [
        AppComponent,
        FullComponent,
        BlankComponent,
        NavigationComponent,
        BreadcrumbComponent,
        SidebarComponent,
        RightSidebarComponent,
        SIDEBAR_TOGGLE_DIRECTIVES,
        LoadingComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgMultiSelectDropDownModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter,
                whitelistedDomains: [
                    'localhost:5000',
                    'admap.mylocalcoin.io',
                    'admap.dev.mylocalcoin.io',
                    'admap.spock.mylocalcoin.io',
                    'admap.stagin.mylocalcoin.io'
                ],
                blacklistedRoutes: []
            }
        }),
        NgbModule
    ],
    providers: [
        Globals,
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorDeviceIDClass, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorErrosClass, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        EventEmitterService,
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
