import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { Globals } from './globals';
import { Observable } from 'rxjs';

@Injectable()
export class InterceptorDeviceIDClass implements HttpInterceptor {
    constructor(
        public globals: Globals
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authReq = req.clone({
            headers: req.headers.set('DeviceID', this.globals.tokenCompleted)
        });
        return next.handle(authReq).catch((error) => {
            return Observable.throw(error);
        }) as any;
    }
}
